<template>
  <div>
    <toastification-loading-content
      ref="loadingToast"
    />

    <breadcrumb
      nameNav="recording_request.recording"
    />

    <b-card class="m-2" no-body  v-if="recordingOptions">
      <div class="m-2">
        <b-card-header class="pb-50 text-capitalize px-0 pt-0">
          <h5
            v-if="$can('manage_request_record_video', 'Video')
            || (moment(recordingOptions.authorized_from) <= moment()
              && moment(recordingOptions.authorized_to) >= moment())"
          >
            {{$t('recording_request.recording')}}
          </h5>
          <h5
            v-if="!$can('manage_request_record_video', 'Video')
            && !(moment(recordingOptions.authorized_from) <= moment()
              && moment(recordingOptions.authorized_to) >= moment())"
          >
            {{ $t('recording_request.time_expired') }}
          </h5>
        </b-card-header>
        <b-row v-if="$can('manage_request_record_video', 'Video') || (moment(recordingOptions.authorized_from) <= moment() && moment(recordingOptions.authorized_to) >= moment())">
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="recordingOptions.camera.name"
                  class="d-inline-block mr-1"
                  disabled
                />
              </div>
            </b-col>
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="recordingOptions.from"
                  class="d-inline-block mr-1"
                  disabled
                />
              </div>
            </b-col>
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="recordingOptions.to"
                  class="d-inline-block mr-1"
                  disabled
                />
              </div>
            </b-col>
            <b-col cols="12" md="3">
              <b-button
                squared
                variant="success"
                size="md"
                :title="$t('recording_request.recording')"
              >
                <feather-icon
                  icon="VideoIcon"
                  size="15"
                  @click="recording(recordingOptions)"
                />
              </b-button>

              <b-button v-b-modal.send_mail
                squared
                variant="info"
                size="md"
                :title="`${$t('actions.send')} ${$t('email')}`"
              >
                <feather-icon
                  icon="MailIcon"
                  size="15"
                />
              </b-button>
            </b-col>
          </b-row>
      </div>
    </b-card>

    <b-card
      class="m-2"
      v-show="showRecording"
      no-body
    >
      <b-row class="justify-content-md-center">
        <b-col cols="12" md="6" sm="12">
          <div class="m-2" id="recording-video"></div>
        </b-col>
      </b-row>
    </b-card>

    <div>
      <b-modal
        id="send_mail"
        :title="`${$t('actions.send')} ${$t('email')}`"
        v-if="modalShow"
        @hidden="resetForm"
        hide-footer
      >
        <template>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- email -->
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group
                  class="text-capitalize"
                  :label="$t('messages.validation.email_placeholder')"
                  label-for="email"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-tags
                    input-id="tags-basic"
                    v-model="email"
                    tag-variant="primary"
                    placeholder=""
                  ></b-form-tags>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-button
                variant="primary"
                class="mt-2 float-right"
                type="submit"
              >
                {{ $t('actions.send')}}
              </b-button>
            </b-form>
          </validation-observer>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormInvalidFeedback,
  BPagination,
  BCardGroup,
  BFormTags,
  BCardHeader,
} from "bootstrap-vue"
import vSelect from "vue-select"
import { ref } from "@vue/composition-api"
import useList from "./useList"
import { useToast } from "vue-toastification/composition"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import ToastificationLoadingContent from '@core/components/toastification/ToastificationLoadingContent.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate"
import axios from "@axios"
import { Camera } from "@omnicloud/cameras-sdk"
import moment from "moment-timezone"
import formValidation from "@core/comp-functions/forms/form-validation"
import { required } from "@validations"
import Breadcrumb from "@/components/Breadcrumb.vue"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardGroup,
    vSelect,
    ToastificationLoadingContent,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormTags,
    BCardHeader,
    Breadcrumb,
  },
  data() {
    return {
      required,
    }
  },
  setup(_, context) {
    const showRecording = ref(false);
    const modalShow = ref(true)
    const loadingToast = ref(null);
    const email = ref(null);

    const resetData = () => {
      email.value = []
    }

    const recording = (recordingOptions) => {
      showRecording.value = !showRecording.value
      let startTime = recordingOptions.from
      let endTime = recordingOptions.to
      let omniviewCamera = new Camera(recordingOptions.camera.camera_type, {
          cameraId: recordingOptions.camera.camera_id,
          token: window.localStorage.accessToken,
          htmlElement: `#recording-video`,
          apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
          style: "float",
          capabilities: {
            closeBtn: false,
            liveBtn: false,
            downloadBtn: true,
            dateRangeBtn: false,
            snapshopBtn: false,
            recordingBtn: false,
          }
      })
      omniviewCamera.buildPlayer('historical', startTime, endTime)
    }

    const onSubmit = () => {
      loadingToast.value.show(context.parent.$i18n.t('messages.info.sending'), 'Enviado correo...', 'secondary')
      modalShow.value = false

      let data = {
        emails: JSON.parse(JSON.stringify(email.value))
      }

      axios.post(`v1/email/recording_request/${idRecording}`, data )
        .then((resp) => {
          if(resp.data.success){
            loadingToast.value.close()
            modalShow.value = true
            toast({
              component: ToastificationContent,
              props: {
                title: resp.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
          else{
            loadingToast.value.close()
            modalShow.value = true
            toast({
              component: ToastificationContent,
              props: {
                title: resp.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          loadingToast.value.close()
          modalShow.value = true
          toast({
            component: ToastificationContent,
              props: {
                title: context.parent.$i18n.t('messages.error.server_error'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
          })
        })
    }

    const toast = useToast();

    const {
      formatDateAssigned,
      recordingOptions,
      idRecording
    } = useList();

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    return {
      loadingToast,
      formatDateAssigned,
      recordingOptions,
      idRecording,
      recording,
      showRecording,
      modalShow,
      email,
      moment,
      resetData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
