import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useList() {

  const toast = useToast()

  const idRecording = router.currentRoute.params.id_recording

  const recordingOptions = ref(null)

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  store.dispatch('recording_request/get', idRecording)
        .then(response => {
          recordingOptions.value = response.data
        })

  toast({
    component: ToastificationContent,
      props: {
        title: "Buscando grabaciones",
        icon: "CheckIcon",
        variant: "success",
      },
  })

  return {
    recordingOptions,
    idRecording,
    formatDateAssigned
  }
}
